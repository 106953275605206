<template>
  <div class="flex justify-center pt-3">
    <div class="pl-3 pr-3"><router-link to="/">Home</router-link></div>

    <div class="pl-3 pr-3">
      <a target="_blank" href="https://www.vub.be/en/privacy" rel="noreferrer"
        >Privacy</a
      >
    </div>
    <div class="pl-3 pr-3">
      <a
        target="_blank"
        href="https://www.vub.be/en/contact-page"
        rel="noreferrer"
        >Contact</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterTop'
}
</script>
