import { render, staticRenderFns } from "./FooterBottom.vue?vue&type=template&id=780748f8&scoped=true&"
import script from "./FooterBottom.vue?vue&type=script&lang=js&"
export * from "./FooterBottom.vue?vue&type=script&lang=js&"
import style0 from "./FooterBottom.vue?vue&type=style&index=0&id=780748f8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "780748f8",
  null
  
)

export default component.exports