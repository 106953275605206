<template>
  <header class="mb-3 bg-gray-200">
    <b-container class="main-nav">
      <b-row align-v="center" class="h-100">
        <b-col xs="12" md="5" class="text-md-left">
          <router-link
            :to="{ name: 'items', params: { lang: this.$i18n.locale } }"
          >
            <img
              width="72px"
              height="72px"
              alt="Logo VUB Uitleendienst"
              src="/logo.png"
            />
          </router-link>
        </b-col>
        <b-col col sm="12" md="7" class="pl-0">
          <b-form inline class="header-buttons">
            <router-link
              v-if="account.Email"
              :to="{ name: 'cart', params: { lang: this.$i18n.locale } }"
            >
              <b-button variant="light" class="mr-3 mb-2 mb-md-0">
                <shopping-cart /> {{ this.$t('cart').toUpperCase() }}
                {{ cartCount > 0 ? cartCount : '' }}
              </b-button>
            </router-link>

            <router-link
              v-if="!account.Email"
              :to="{ name: 'login', params: { lang: this.$i18n.locale } }"
              login
            >
              <b-button variant="grey" class="mr-3">{{
                $t('header.login')
              }}</b-button>
            </router-link>
            <router-link
              v-if="account.Email"
              :to="{
                name: 'account-user',
                params: { lang: this.$i18n.locale }
              }"
            >
              <b-button variant="grey" class="mr-3 pl-3 pr-3 mb-2 mb-md-0">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="user-circle"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 496 512"
                  class="svg-inline--fa fa-user-circle fa-w-16 fa-5x"
                >
                  <path
                    fill="currentColor"
                    d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
                    class=""
                  ></path></svg
                >&nbsp;{{ $t('header.myAccount') }}
              </b-button>
            </router-link>
            <b-button
              v-if="account.Email"
              @click="logout"
              class="mr-0 pl-3 pr-3 mb-2 mb-md-0"
              variant="grey"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="sign-out-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="svg-inline--fa fa-sign-out-alt fa-w-16 fa-5x"
              >
                <path
                  fill="currentColor"
                  d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
                  class=""
                ></path></svg
              >&nbsp;{{ $t('header.logout') }}
            </b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
    <div class="mobile-nav-filling"></div>
    <mobile-nav :account="account" class="mobile-nav" />
  </header>
</template>

<script>
import MobileNav from './MobileNav.vue'
import ShoppingCart from '@/components/icons/IconShoppingCart.vue'

export default {
  components: {
    MobileNav,
    ShoppingCart
  },
  props: {
    windowWidth: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      searchTerm: ''
    }
  },
  computed: {
    user: function() {
      return this.$store.getters.user
    },
    account: function() {
      return this.$store.getters.account ?? {}
    },
    cartCount() {
      return this.$store.getters.cartCount
    }
  },
  methods: {
    logout: async function() {
      await this.$store.commit('unsetCart')
      await this.$store.commit('unsetUser', {
        reason: 'loggedOut'
      })

      //this.$router.push({ name: 'index' })
      window.location.reload()
    },
    search: async function(event) {
      event.preventDefault()
      if (this.searchTerm.length > 1) {
        await this.$router.push({
          name: 'search',
          params: { searchTerm: this.searchTerm.toLowerCase() }
        })
        this.searchTerm = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 500px) {
  .logo {
    max-width: 215px;
  }
}
@media screen and (min-width: 767px) {
  .mobile-nav,
  .mobile-nav-filling {
    display: none !important;
  }
  .main-nav,
  .secondary-nav {
    display: block !important;
  }
}
.main-nav,
.secondary-nav {
  display: none;
}
.mobile-nav-filling {
  height: 45px;
  position: relative;
}
.mobile-nav {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
}
.language-picker {
  padding-left: 46px;
}
.main-nav {
  background-color: #e5eaeb;
}

.header-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 10px;
  padding-top: 10px;
}
.container {
  background-color: transparent;
}
.logo {
  height: 50px;
  margin-top: 10px;
}
.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 0;
  font-size: 0.875rem;
  > .container,
  > .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .topbar-text,
  .topbar-link {
    display: inline-block;
    margin-bottom: 0;
    text-decoration: none !important;
    > i {
      margin-right: 0.375rem;
      font-size: 1.15em;
      vertical-align: middle;
    }
  }

  // Carousel inside topbar
  .cz-carousel {
    [data-controls] {
      margin-top: -1rem;
      width: 2rem;
      height: 2rem;
      transition: color 0.2s ease-in-out;
      border: 0;
      background: 0;
      font-size: 0.9558823529rem;
      line-height: 2rem;
    }
    [data-controls='prev'] {
      left: -2.2rem;
    }
    [data-controls='next'] {
      right: -2.2rem;
    }
  }
}
@-moz-document url-prefix() {
  .topbar .cz-carousel [data-controls] {
    margin-top: -0.875rem;
  }
}
</style>
